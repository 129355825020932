import React, { Component } from "react";
import BuscarSitio from "./BuscarSitio";
import { Link } from "react-router-dom";
import logoperu from "../images/simmetric.png";
export class Navigation extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-light bg-light">
          <Link
            className="navbar-brand "
            style={{ paddingTop: "1.5rem" }}
            to="/mapa"
          >
            <img src={logoperu} alt="Logo Moroha" height="100px" />
          </Link>

          <button
            className="navbar-toggler d-sm-none"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="container">
              <BuscarSitio />
            </div>
          </div>
          <form className="form-inline   d-none d-lg-block d-md-block d-xl-block">
            <div className="row justify-content-center">
              <div className="col-10">
                <BuscarSitio />
              </div>
            </div>
          </form>
        </nav>
      </div>
    );
  }
}

export default Navigation;
